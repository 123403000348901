import { Button, Form, Input, message, Typography } from "antd";

import { useUser } from "$/state/user";

import { IPageBaseProps } from "$/interfaces";
import { useState } from "react";

interface IProps extends IPageBaseProps {}

const { Title } = Typography;

export const UserNameSettings = (props: IProps) => {
  const user = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onUpdateUser = async (values: any) => {
    setIsSubmitting(true);
    try {
      await user.update({
        firstName: values.firstName,
        lastName: values.lastName,
      });

      message.success("Profile updated succesfully");
    } catch (err) {
      message.error("Failed to update name. Please try again");
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Title level={2}>Update Name</Title>

      <Form
        layout="vertical"
        name="userInfo"
        onFinish={onUpdateUser}
        initialValues={{
          firstName: user.currentUser?.firstName,
          lastName: user.currentUser?.lastName,
        }}
      >
        <Form.Item name="firstName" label="First Name">
          <Input size="large" placeholder="First Name" id="first-name-input" />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name">
          <Input size="large" placeholder="Second Name" id="second-name-input" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block loading={isSubmitting}>
            Save changes
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
