import React from "react";
import { useNavigate } from "react-router";
import { useScrollTo } from "$/hooks";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { CoachCard } from "$/components/CoachCard";
import { CompanyFeaturePermissions } from "$/state/company";
import { usePermissionRedirect } from "$/hooks/usePermissionRedirect";
import { Mixpanel } from "$/tracking";
import { IPageBaseProps } from "$/interfaces";
import { UserRole, useSearchCoachesByAvailabilityQuery } from "$/graphql/types.generated";
import styles from "../Coaches/styles.module.less";
import { ArrowLeft } from "lucide-react";
import { useUser } from "$/state/user";
import { DateTime } from "luxon";
import { formatTags } from ".";
import { Tags } from "$/components/TopicSelector";
import * as jose from "jose";

interface IProps extends IPageBaseProps {
  timeSlot: DateTime;
  selectedTags: Tags;
}

export const CoachOptions: React.FC<IProps> = ({ timeSlot, selectedTags }) => {
  useScrollTo();
  usePermissionRedirect(CompanyFeaturePermissions.coachDirectory);

  const user = useUser();
  const navigate = useNavigate();

  const formattedTags = formatTags(selectedTags);

  const availability = {
    startsAt: timeSlot.toISO(),
    endsAt: timeSlot.plus({ hours: 1 }).toISO(),
  };

  const whereVariables = {
    where: {
      published: {
        equals: true,
      },
      ...(user?.currentUser?.role === UserRole.Individual || !user?.currentUser
        ? { excludeIndividuals: { not: { equals: true } } }
        : {}),
      ...formattedTags,
    },
  };

  const searchCoachesByAvailability = useSearchCoachesByAvailabilityQuery(
    {
      ...whereVariables,
      availability,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div>
      <div className={styles["coach-results-container"]}>
        {searchCoachesByAvailability.isLoading ? (
          <div className="mt-4">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="gap-2">
            <div className="w-full text-center pt-2">
              <h3>You&apos;ve selected: {timeSlot.toFormat("ccc dd MMM 'at' h:mm a")}</h3>
              <h3>Here are the coaches matching your choices that are available at this time:</h3>
            </div>
            {searchCoachesByAvailability.data?.searchCoachesByAvailability
              ?.filter((coach) => coach.events.length > 0)
              .map((coach) => (
                <CoachCard
                  key={coach?.handle!}
                  coach={coach}
                  buttons={[
                    {
                      icon: <ArrowLeft size={16} />,
                      text: "Select this coach",
                      onClick: async () => {
                        Mixpanel.track("Next available: Select this coach");

                        const tagsJwt = await new jose.SignJWT(selectedTags)
                          .setProtectedHeader({ alg: "HS256" })
                          .sign(new TextEncoder().encode("secret"));

                        navigate(
                          `/coach/${coach?.handle}/confirm?date=${timeSlot.toFormat(
                            "yyyyMMdd",
                          )}&time=${timeSlot.hour}&t=${tagsJwt}&skipTags=true`,
                        );
                      },
                      primary: true,
                    },
                  ]}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
