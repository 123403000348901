import { enquirySections } from "../states";
import cn from "classnames";

const ProgressSection = ({
  label,
  value,
  end = false,
}: {
  label: string;
  value: number;
  end?: boolean;
}) => {
  return (
    <div className={cn("flex flex-col text-center basis-1/4", { "mr-2": !end })}>
      <div
        className={cn("flex items-center justify-center mb-2 text-sm md:text-base min-h-[40px]", {
          "font-semibold": value > 0 && value < 100,
        })}
      >
        {label}
      </div>
      <progress
        value={value}
        max="100"
        className="w-full h-[5px] progress-filled:bg-yellow progress-unfilled:rounded-md progress-unfilled:bg-white"
      />
    </div>
  );
};

export const ProgressBar = ({ progress }) => {
  const section1 = enquirySections["what-you-want"].length;
  const section2 = enquirySections["our-programmes"].length;
  const section3 = enquirySections["your-coach"].length;
  const section4 = enquirySections["select-programme"].length;

  const whatDoYouWant = (progress / section1) * 100;
  const ourProgrammes = ((progress - section1) / section2) * 100 + 5;
  const yourCoach = ((progress - section1 - section2) / section3) * 100 + 5;
  const selectProgramme = ((progress - section1 - section2 - section3) / section4) * 100 + 5;

  return (
    <div className="flex w-full">
      <ProgressSection value={whatDoYouWant > 0 ? whatDoYouWant : 5} label="About you" />
      <ProgressSection value={ourProgrammes} label="Your programme" />
      <ProgressSection value={yourCoach} label="Your coach" />
      <ProgressSection value={selectProgramme} label="Book your first session" end={true} />
    </div>
  );
};
