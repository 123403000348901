import { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Image, Layout, Typography } from "antd";
import { HtmlMeta } from "$/components/HtmlMeta";
import { Message } from "$/components/Message";
import { WEB_ASSETS_URL } from "$/configs/app.config";
import { Mixpanel } from "$/tracking";
import { Controller, useForm } from "react-hook-form";
import { Input } from "$/components/Input";
import { Eye, EyeOff } from "lucide-react";
import { Button } from "$/components/Button";
import * as z from "zod";
import * as CompanyEmailValidator from "company-email-validator";
import { zodResolver } from "@hookform/resolvers/zod";
import { AccountType, useCreateAccountMutation } from "$/graphql/types.generated";
import Checkbox from "$/components/Checkbox";
import cn from "classnames";
import { useAuth } from "$/state/auth";

const schema = z.object({
  email: z
    .string()
    .min(1, "Please enter your email")
    .refine(
      (email: string) => CompanyEmailValidator.isCompanyEmail(email),
      "You must use your work email",
    ),
  firstName: z.string().min(1, "Please enter your first name"),
  lastName: z.string().min(1, "Please enter your last name"),
  password: z.string().min(8, `Please enter a password of at least 8 characters`),
  companyName: z.string().min(1, "Please enter your company name"),
  termsAndConditions: z
    .boolean()
    .refine((val) => val, "You must agree to the terms and conditions"),
});

/**
 * Form Validation Schema
 */
type FormInputs = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  companyName?: string;
  termsAndConditions: boolean;
};

const defaultValues = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  companyName: "",
  termsAndConditions: false,
};

export const BusinessOrPersonal = ({ onClick, isSubmitting }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const individualSubscription = searchParams.get("individualSubscription");

  return (
    <div className="flex flex-col items-center justify-center gap-y-4">
      <div className="text-2xl font-semibold">Choose the best package that fits your team</div>
      <div className="bg-white p-8 rounded-2xl">
        {individualSubscription === "allow" && (
          <>
            <div>
              <h2>Create a personal account</h2>
              <p>
                Choose this option if you&apos;ll manage the payment of More Happi yourself, this
                includes using your personal credit card or a service such as Thanks Ben where you
                are given a virtual card.
              </p>
              <p>
                <i>You will not be able to invite teammates if you choose this option.</i>
              </p>
              <Button
                primary
                onClick={(e) => onClick(e, AccountType.Individual)}
                isSubmitting={isSubmitting}
                className="ml-6"
              >
                Create personal account
              </Button>
            </div>
            <hr className="border-lightGrey my-8" />
          </>
        )}
        <div>
          <h2>Sign up for monthly subscription</h2>
          <p className="font-semibold">Best option for small teams</p>
          <p>
            The monthly membership offers more flexibility with a rolling subscription that is
            billed in advance. It is easy to manage your subscription from the app, including
            updating members, which automatically amends your next bill.
          </p>
          <Button primary onClick={(e) => onClick(e, AccountType.Free)} isSubmitting={isSubmitting}>
            Create account for my team
          </Button>
        </div>
        <hr className="border-lightGrey my-8" />
        <div>
          <h2>Speak to us about an annual contract</h2>
          <p className="font-semibold">Discounts available for large teams</p>
          <p>
            The annual membership offers best value for money as the more members you add, the lower
            the cost per user. Annual memberships are billed quarterly in advance. Book a call to
            find out more or plan your launch.
          </p>
          <div className="flex justify-center gap-6">
            <a href={"https://morehappi.com/book-a-call"} target="_blank" rel="noreferrer">
              <Button tertiarySolid>Book a call</Button>
            </a>
          </div>
        </div>
        <hr className="border-lightGrey my-8" />
        <div className="m-0">
          <a href={"https://morehappi.com/our-pricing"} target="_blank" rel="noreferrer">
            <Button tertiary>Learn more about our pricing</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export const RegistrationForm = ({
  register,
  control,
  errors,
  error,
  submit,
  accountType,
  isSubmitting,
  showHeader = true,
  className = "",
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="flex flex-col gap-y-8">
      <>
        {showHeader ? (
          <>
            <Typography.Title level={1}>Create your new account</Typography.Title>
            <div className="m-lg">
              <Typography.Title level={5}>
                Take the first step towards unlimited coaching.
              </Typography.Title>
            </div>
          </>
        ) : null}

        <form id={`registration-form-${accountType}`}>
          <div
            className={cn(
              "w-full max-w-[400px] mx-auto justify-center flex flex-col items-center gap-y-1",
              className,
            )}
          >
            <Input
              label="Work email"
              name="email"
              id="email"
              type="email"
              required
              register={register}
              pattern={{
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              }}
              error={errors.email}
              placeholder="your@work-email.com"
            />
            <Input
              label="First Name"
              name="firstName"
              id="firstName"
              required
              register={register}
              error={errors.lastName}
            />
            <Input
              label="Last Name"
              name="lastName"
              id="lastName"
              required
              register={register}
              error={errors.lastName}
            />
            <Input
              label="Company Name"
              name="companyName"
              id="companyName"
              required
              register={register}
              error={errors.lastName}
            />
            <Input
              label="Create a new password"
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              required
              register={register}
              pattern={{
                value: /^(?!.* )(?=.*[0-9])(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
                message: "Must contain at least 8 characters, 1 letter and 1 number",
              }}
              rightIcon={
                <div
                  className="h-full flex items-center cursor-pointer"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <Eye size="1.3rem" /> : <EyeOff size="1.3rem" />}
                </div>
              }
              error={errors.password}
            />
            <Controller
              name="termsAndConditions"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Checkbox
                  label={
                    <div>
                      I agree to these{" "}
                      <a
                        href={
                          accountType === "individual"
                            ? `${WEB_ASSETS_URL}/individual-terms.pdf`
                            : `${WEB_ASSETS_URL}/team-terms.pdf`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        terms and conditions
                      </a>
                    </div>
                  }
                  required
                  id="termsAndConditions"
                  {...field}
                  checked={field.value}
                  error={errors.termsAndConditions}
                />
              )}
            />

            <Button
              primary
              className="mt-4 mb-6"
              isSubmitting={isSubmitting}
              disabled={isSubmitting}
              onClick={(e) => {
                submit(e);
              }}
            >
              Create account
            </Button>
          </div>

          {error && <Message type="error" items={[{ message: error }]} />}
        </form>
      </>
    </div>
  );
};

export const Register = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [businessOrPersonal, setBusinessOrPersonal] = useState(!searchParams.get("t"));
  const [accountType, setAccountType] = useState(searchParams.get("t"));
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });
  const createAccount = useCreateAccountMutation();

  const onSubmitRegistration = async (values: FormInputs & { accountType: string }) => {
    setError(null);

    try {
      setLoading(true);

      const email = values.email?.trim().toLowerCase();

      const newUser = await Auth.signUp({
        username: email,
        password: values.password,
        attributes: {
          email,
          given_name: values.firstName,
          family_name: values.lastName,
          "custom:companyName": values.companyName.trim().length ? values.companyName : "Personal",
          "custom:role": "admin",
          "custom:type": values.accountType,
        },
      });

      await createAccount.mutateAsync({
        input: {
          cognitoId: newUser.userSub,
          companyName: values.companyName.trim().length ? values.companyName : "Personal",
          email,
          firstName: values.firstName,
          lastName: values.lastName,
          type: values.accountType,
        },
      });

      await Auth.signIn(email, values.password);

      Mixpanel.track("Company Created", {
        companyName: values.companyName.trim().length ? values.companyName : "Personal",
      });

      await auth.initializeUser();

      setLoading(false);

      navigate(`/`);
    } catch (err: any) {
      setError(err.message);

      return setLoading(false);
    }
  };

  return (
    <Layout className="mh-layout-public">
      <HtmlMeta title="Sign Up" />

      <Layout.Content>
        <div className="site-login-content">
          <div className="login-wrap">
            <a href="https://morehappi.com">
              <Image
                className="login-img"
                preview={false}
                src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
                alt="logo"
              />
            </a>

            {businessOrPersonal ? (
              <BusinessOrPersonal
                onClick={(e, _accountType: string) => {
                  setBusinessOrPersonal(false);
                  setAccountType(_accountType);
                }}
                isSubmitting={isSubmitting}
              />
            ) : (
              <RegistrationForm
                register={register}
                errors={errors}
                error={error}
                accountType={accountType}
                control={control}
                isSubmitting={isSubmitting || loading || createAccount.isPending}
                submit={(e) =>
                  handleSubmit(
                    () => {
                      handleSubmit((values: z.infer<typeof schema>) =>
                        onSubmitRegistration({ ...values, accountType }),
                      )(e);
                    },
                    () => {},
                  )(e)
                }
              />
            )}
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};
