import { Button } from "$/components/Button";
import { useUser } from "$/state/user";
import { Mixpanel } from "$/tracking";
import { ArrowRight } from "lucide-react";

export const SessionChoiceCard = () => {
  const user = useUser();

  return (
    <div className="flex flex-col bg-white p-4 rounded-xl w-full md:w-1/2 lg:w-1/3">
      <div className="flex flex-col items-center text-center gap-x-4 pt-2">
        <h3 className="text-2xl font-semibold">Single Session</h3>
        <img src={"/images/Landing_Page.svg"} alt={"Session Ladder"} className="w-2/3" />
        <p className="m-0 pt-2">
          A one off 45 minute session with a coach. Best if you have a specific coach you&apos;d
          like to work with, or you&apos;d like to be assigned a coach for a time and date that
          works for you.
        </p>
      </div>
      <div className="flex justify-center pt-2">
        <a href={"/book-coaching/single-session"}>
          <Button
            primary
            onClick={() =>
              Mixpanel.track("Book a Single Session clicked", {
                from: "Book Coaching",
                user: user.currentUser,
              })
            }
            rightIcon={<ArrowRight size={18} />}
          >
            Book a Single Session
          </Button>
        </a>
      </div>
    </div>
  );
};
