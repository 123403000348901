import { Button } from "$/components/Button";
import { Send } from "..";

export const ExplainImage = ({
  send,
  title,
  subtitle,
  body,
  next,
}: {
  send: Send;
  title: string;
  subtitle: string;
  body: string;
  next?: string;
}) => {
  return (
    <>
      <h1 className="text-center text-2xl md:text-4xl font-semibold mb-9 max-w-[800px]">{title}</h1>
      <p className="text-center mb-14 max-w-[800px] text-lg whitespace-break-spaces">{subtitle}</p>
      <div className="w-full flex flex-wrap gap-4 items-center justify-center whitespace-break-spaces">
        {body}
      </div>
      <Button primary large className="w-full mt-8 max-w-[800px]" onClick={() => send({ next })}>
        Continue
      </Button>
    </>
  );
};
