import React, { useEffect } from "react";
import { HtmlMeta } from "$/components/HtmlMeta";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetEnquiryQuery, useGetEnquiryResultsQuery } from "$/graphql/types.generated";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import CoachCarousel from "$/components/CoachCarousel";
import ResultCoachCard from "./components/ResultCoachCard";
import { ArrowRight } from "lucide-react";
import { Button } from "$/components/Button";
import { Mixpanel } from "$/tracking";
import { useUser } from "$/state/user";
import { useCompanySubscription } from "$/state/company-subscription";
import { useFeatures } from "$/state/features";
import { ProgressBar } from "./components/ProgressBar";
import { canUserAddPackage } from ".";

interface IProps extends IPageBaseProps {}

export const EnquiryResultsPage: React.FC<IProps> = () => {
  useScrollTo();
  const navigate = useNavigate();
  const features = useFeatures();
  const user = useUser();
  const companySubscription = useCompanySubscription();
  const [searchParams] = useSearchParams();
  const [forcedHold, setForcedHold] = React.useState(true);

  const enquiryQuery = useGetEnquiryQuery({ where: { id: searchParams.get("eid") } });

  const getEnquiryResults = useGetEnquiryResultsQuery({ where: searchParams.get("eid") });

  useEffect(() => {
    // We force the user to see loading for at least 5 seconds.
    // This is to give the user a sense that we are doing something in the background even if the queries are quick
    setTimeout(() => {
      setForcedHold(false);
    }, 1000);
  }, []);

  const coaches = getEnquiryResults.isFetched
    ? [...(getEnquiryResults.data.getEnquiryResults.matchingTimes ?? [])].slice(0, 3)
    : null;

  const allCoaches = getEnquiryResults.isFetched
    ? [...(getEnquiryResults.data.getEnquiryResults.allCoaches ?? [])]
    : null;

  const coachesWithApproaches = getEnquiryResults.isFetched
    ? [...(getEnquiryResults.data.getEnquiryResults.matchingApproaches ?? [])]
    : null;

  const coachesWithTimes = getEnquiryResults.isFetched
    ? [...(getEnquiryResults.data.getEnquiryResults.matchingTimes ?? [])].slice(0, 3)
    : null;

  const finalCoaches = [];

  if (
    getEnquiryResults.isFetched &&
    enquiryQuery?.data?.getEnquiry?.enquiryChoices.find((choice) => choice.key === "whatDay")
      .value !== "Flexible"
  ) {
    finalCoaches.push(...coachesWithTimes.slice(0, 3));
  }

  if (getEnquiryResults.isFetched && finalCoaches.length < 3) {
    const remainingApproaches = coachesWithApproaches
      .filter((coach) => !finalCoaches.some((finalCoach) => finalCoach.id === coach.id)) // Filter out duplicates
      .slice(0, 3 - finalCoaches.length); // Slice based on remaining space in finalCoaches

    finalCoaches.push(...remainingApproaches);
  }

  if (getEnquiryResults.isFetched && finalCoaches.length < 3) {
    const remainingAllCoaches = allCoaches
      .filter((coach) => !finalCoaches.some((finalCoach) => finalCoach.id === coach.id)) // Filter out duplicates
      .slice(0, 3 - finalCoaches.length); // Slice based on remaining space in finalCoaches

    finalCoaches.push(...remainingAllCoaches);
  }

  const coachNames = getEnquiryResults.isFetched
    ? finalCoaches
        ?.map((coach) => coach.displayName?.split(" ")[0])
        ?.reduce((acc, curr, index) => {
          if (index === finalCoaches.length - 1) return `${acc} or ${curr}`;
          else return `${acc}, ${curr}`;
        })
    : "";

  return (
    <div className="w-screen">
      <div className="mx-auto">
        <HtmlMeta title="Enquiry results" />
        <div className="flex flex-col justify-between items-center w-full px-2 xl:px-0 xl:w-[1000px] mx-auto">
          <div className="rounded-lg w-full px-4 py-6">
            <ProgressBar progress={8} />
            <div className="flex flex-col items-center justify-center mt-6">
              {getEnquiryResults.isLoading || forcedHold ? (
                <div className="flex flex-col w-full h-[70vh] items-center justify-center">
                  <h1 className="text-4xl mb-10">Finding the best coaches for you</h1>
                  <LoadingSpinner className="!h-12 !w-12" />
                </div>
              ) : (
                <>
                  <h1 className="text-center text-4xl font-semibold mb-6 max-w-[800px]">
                    These coaches are your top matches.
                  </h1>

                  {coachesWithApproaches.length === 0 && (
                    <p className="text-lg text-center font-semibold">
                      Unfortunately, none of our coaches that specialise in the area you selected
                      have upcoming slots avilable. <br />
                      You could try expanding your search to find suitable matches.
                    </p>
                  )}
                  {coachesWithTimes.length === 0 && coachesWithApproaches.length > 0 && (
                    <p className="text-lg text-center font-semibold">
                      Unfortunately, none of our coaches have upcoming times that match your
                      preferences. <br />
                      We have shown your best matches based on experience. <br />
                      You could try expanding your availability to find suitable matches.
                    </p>
                  )}
                  {coachesWithTimes.length > 0 && coachesWithTimes.length < 3 && (
                    <p className="text-lg text-center font-semibold">
                      Only some of our coaches have upcoming times that match your preferences.
                      <br />
                      We have shown other best matches based on experience. <br />
                      You could try expanding your availability to find more suitable matches.
                    </p>
                  )}
                  {coachesWithTimes.length >= 3 && coachesWithApproaches.length >= 3 && (
                    <p className="text-lg">
                      Based on your preferences, these coaches will help you reach your goals
                      faster.
                    </p>
                  )}
                  <p className="text-lg">
                    Book your first session with {coachNames} to walk you through their approach and
                    next steps.
                  </p>

                  <div className="flex flex-wrap lg:flex-nowrap flex-col md:flex-row gap-4 justify-center">
                    {canUserAddPackage(user.currentUser, companySubscription.state, features) ? (
                      finalCoaches.map((coach) => (
                        <ResultCoachCard
                          key={coach.id}
                          coach={coach}
                          onSelect={() =>
                            navigate(
                              `/enquiry/booking/${coach.handle}?ctx=enquiry&eid=${searchParams.get(
                                "eid",
                              )}`,
                            )
                          }
                        />
                      ))
                    ) : (
                      <div className="w-[70%] overflow-hidden mx-auto">
                        <CoachCarousel coaches={coaches} trackingPrefix="enquiry" />
                      </div>
                    )}
                    <div className="flex flex-col bg-yellow text-center rounded-2xl min-h-[500px] md:w-[250px]">
                      <div>
                        <img
                          src="/images/Book_a_session.svg"
                          alt=""
                          className="h-[250px] w-[250px]"
                        />
                      </div>
                      <div className="p-3">
                        <h2 className="text-2xl font-semibold mb-1">
                          Not who you were looking for?
                        </h2>
                        <p className="text-sm md:text-lg">
                          We have over 100 qualified coaches working with us, so you&apos;re sure to
                          find someone you want to connect with.
                        </p>
                        <a href={`/coaches?ctx=enquiry&eid=${searchParams.get("eid")}`}>
                          <Button
                            secondary
                            className="bg-white text-black w-fit"
                            rightIcon={<ArrowRight />}
                            onClick={() => {
                              Mixpanel.track("Enquiry: Show all coaches");
                            }}
                            id="show-all-coaches-enquiry"
                          >
                            Show me all coaches
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
