import { Button, Form, Input, Typography, message } from "antd";

import { useUser } from "$/state/user";

import { IPageBaseProps } from "$/interfaces";
import { useUpdateUserMutation } from "$/graphql/types.generated";
import { useState } from "react";

interface IProps extends IPageBaseProps {}

const { Title } = Typography;

export const UserEmailSettings = (props: IProps) => {
  const user = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onUpdateUser = async (values: any) => {
    setIsSubmitting(true);
    try {
      await user.update({
        email: values.email,
      });

      message.success("Profile updated succesfully");
    } catch (err) {
      message.error(
        "Failed to update email. This may be an error or that the email is already in use. Please try again or contact support.",
      );
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Title level={2}>Update Email</Title>

      <Form
        layout="vertical"
        name="userEmail"
        initialValues={{
          email: user?.currentUser?.email,
        }}
        onFinish={onUpdateUser}
      >
        <Form.Item name="email" label="Email">
          <Input size="large" placeholder="Email" id="email-input" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block loading={isSubmitting}>
            Save changes
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
