import { Button } from "$/components/Button";
import { Star } from "$/components/Icons";
import { Modal } from "$/components/Modal";
import { useIsMobile } from "$/hooks";
import { useUser } from "$/state/user";
import { Mixpanel } from "$/tracking";
import { ArrowRight } from "lucide-react";
import { useState } from "react";

export const ProgrammeChoiceCard = () => {
  const user = useUser();
  const isMobile = useIsMobile();
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  return (
    <div className="relative flex flex-col bg-white p-4 rounded-xl w-full md:w-1/2 lg:w-1/3">
      <div className="absolute -top-3 left-[25%] right-0 flex w-1/2 bg-[#D5881C] rounded-md justify-center z-10 gap-2">
        <Star stroke="#F5C900" fill={"#F5C900"} size={20} />
        <p className="m-0 text-white">Most Popular</p>
      </div>
      <div className="flex flex-col items-center text-center gap-x-4 pt-2">
        <h3 className="text-2xl font-semibold">Coaching Programme</h3>
        <img src={"/images/Sponsor_checklist.svg"} alt={"Programme Baloon"} className="w-2/3" />
        <p className="m-0 pt-2">
          Best if you are new to coaching and want meaningful results. A series of 3 or 6 regular 45
          minute sessions with a Coach, focusing on a specific goal or challenge.{" "}
          <a
            className="underline cursor-pointer"
            onClick={() => {
              setShowExtraInfo(true);
              Mixpanel.track("View Programme Extra Info", {
                user: user.currentUser,
              });
            }}
          >
            Learn more
          </a>
        </p>
      </div>
      <div className="flex justify-center pt-2">
        <a href={"/enquiry"}>
          <Button
            primary
            onClick={() =>
              Mixpanel.track("Book a Programme clicked", {
                from: "Book Coaching",
                user: user.currentUser,
              })
            }
            rightIcon={<ArrowRight size={18} />}
          >
            Book a Programme
          </Button>
        </a>
      </div>
      {showExtraInfo && (
        <Modal
          onClose={() => {
            setShowExtraInfo(false);
          }}
          size={isMobile ? "lg" : "xl"}
        >
          <div>
            <div className="relative flex flex-col bg-white md:p-4 rounded-xl w-full">
              <div className="flex flex-col text-center justify-center pt-4">
                <h2 className="text-4xl"> How our Coaching Programmes work:</h2>
              </div>

              <div className="w-full flex flex-col justify-between p-4 gap-4 md:flex-row">
                <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
                  <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
                    1
                  </div>
                  <h2 className="max-w-[500px] text-xl mb-0">Tell us about you</h2>
                  <p className="max-w-[500px] text-lg mb-0">
                    You&apos;ll start by completing a short quiz setting you up with the programme
                    specifically designed to foster your growth, confidence and skills around your
                    chosen topic.
                  </p>
                </div>
                <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
                  <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
                    2
                  </div>
                  <h2 className="max-w-[500px] text-xl mb-0">Find the perfect coach</h2>
                  <p className="max-w-[500px] text-lg mb-0">
                    Based on your responses, you&apos;ll choose a coach from your top matches, or
                    browse our full list of coaches. You&apos;ll have the same coach throughout,
                    with the option to swap coach after your first session.
                  </p>
                </div>
                <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
                  <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
                    3
                  </div>
                  <h2 className="max-w-[500px] text-xl mb-0">Start your programme</h2>
                  <p className="max-w-[500px] text-lg mb-0">
                    Book your preferred coach and time instantly. Your coach and you will discuss
                    your needs during your programme, and adapt as needed &ndash; supporting you at
                    every step.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
