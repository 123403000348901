import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { HtmlMeta } from "$/components/HtmlMeta";
import { SubscriptionPermission } from "$/components/SubscriptionPermission";
import { Tags, TopicSelector, initialTags } from "$/components/TopicSelector";
import { Button } from "$/components/Button";
import { useUser } from "$/state/user";
import { useCompany } from "$/state/company";
import { useCompanySubscription } from "$/state/company-subscription";
import { SchedulerProvider, useScheduler } from "../Scheduler/schedulerContext";
import { SchedulerTimes } from "../Scheduler/SchedulerTimes";
import { Scheduler } from "../Scheduler";
import { ROUTE_PATHS } from "$/configs/routes";
import { Link, useSearchParams } from "react-router-dom";
import {
  CoachEvent,
  UserRole,
  useSearchCoachesByAvailabilityQuery,
} from "$/graphql/types.generated";
import { merge, sortBy } from "lodash";
import * as jose from "jose";
import { DateTime } from "luxon";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { CoachOptions } from "./CoachOptions";
import { ArrowLeft } from "lucide-react";
import cn from "classnames";

interface TagSelectorProps {
  selectedTags: Tags;
  setSelectedTags: Dispatch<SetStateAction<Tags>>;
  submit: () => void;
}

const TagSelector = ({ selectedTags, setSelectedTags, submit }: TagSelectorProps) => {
  const [hasSelectedTags, setHasSelectedTags] = useState(
    Object.values(selectedTags).some((tag) => tag.length > 0),
  );

  useEffect(() => {
    setHasSelectedTags(Object.values(selectedTags).some((tag) => tag.length > 0));
  }, [{ ...selectedTags }]);

  return (
    <div>
      <h2>What will your session be about?</h2>
      <p className="m-0">
        We will filter based on these needs where possible. The fewer items you select, the more
        availability you will see.
      </p>
      <p>
        Please note, that our coaches need at least 24 hours notice for a booking, so you cannot
        book a session within the next 24 hours.
      </p>
      <TopicSelector selectedTags={selectedTags} handleChange={setSelectedTags} />
      <div className="w-full flex flex-col-reverse md:flex-row mt-8 justify-between gap-4">
        {hasSelectedTags ? (
          <Button
            secondary
            className="border-bottleGreen text-bottleGreen w-full md:w-[50%]"
            large
            onClick={() => {
              setSelectedTags(initialTags);
              setHasSelectedTags(false);
            }}
          >
            Reset
          </Button>
        ) : (
          <Button
            secondary
            className="text-bottleGreen border-bottleGreen border-solid w-full md:w-[50%]"
            large
            onClick={submit}
          >
            Skip
          </Button>
        )}
        <Button
          className="bg-bottleGreen border-bottleGreen border-solid text-white w-full md:w-[50%]"
          large
          onClick={submit}
        >
          {hasSelectedTags ? "Next" : "Show me all coaches"}
        </Button>
      </div>
    </div>
  );
};

export const formatTags = (tags: Tags) => {
  const categories = tags.topics.length
    ? { categories: { some: { id: { in: tags.topics } } } }
    : null;
  const audienceExpriences = tags.experiences.length
    ? { audienceExperiences: { some: { id: { in: tags.experiences } } } }
    : null;
  const specialisms = tags.specialities.length
    ? { specialisms: { some: { id: { in: tags.specialities } } } }
    : null;
  const approaches = tags.approaches.length
    ? { approaches: { some: { id: { in: tags.approaches } } } }
    : null;

  return merge({}, categories, audienceExpriences, specialisms, approaches);
};

const Schedule = ({ canBookSession, selectedTags, setStage, setSlot }) => {
  const user = useUser();
  const { setEvents, month, availableSlots } = useScheduler();
  const [showTimeScheduler, setShowTimeScheduler] = useState(false);

  const start = DateTime.now().hasSame(month, "month")
    ? DateTime.now().startOf("hour").plus({ day: 1 }).toISO()
    : month.startOf("month").toISO();
  const end = month.endOf("month").toISO();

  const _where = merge({
    events: {
      some: {
        startsAt: {
          gte: start,
        },
        endsAt: {
          lte: end,
        },
      },
    },
    ...(user?.currentUser?.role === UserRole.Individual || !user?.currentUser
      ? { excludeIndividuals: { not: { equals: true } } }
      : {}),
    published: { equals: true },
    ...formatTags(selectedTags),
  });

  const { data, isLoading, isFetching } = useSearchCoachesByAvailabilityQuery({
    where: _where,
    take: 10,
    skip: 0,
  });

  function groupSlots(accumulator: CoachEvent[], currentValue: CoachEvent) {
    const newArray = [...accumulator];

    if (!accumulator?.find((element) => element.startsAt === currentValue.startsAt)) {
      newArray.push(currentValue);
    }
    return newArray;
  }

  useEffect(() => {
    if (!isFetching && data.searchCoachesByAvailability?.length) {
      setEvents(
        sortBy(
          data.searchCoachesByAvailability
            .map((coach) => coach.events.map((e) => ({ ...e, coach: { handle: coach?.handle } })))
            .flat()
            .reduce(groupSlots, []),
          ["startsAt"],
        ) as Array<CoachEvent>,
      );
    }
  }, [isFetching, month.month]);

  return (
    <div>
      {isFetching ? (
        <div className="w-full flex flex-col gap-4 items-center justify-center h-[465px] md:h-[646px]">
          <h1>Loading availability</h1>
          <LoadingSpinner className="!h-12 !w-12" />
        </div>
      ) : (
        <div key={`${month.toISO()}`} className="w-full p-2">
          {canBookSession ? (
            showTimeScheduler ? (
              <SchedulerTimes
                hideTimeScheduler={() => setShowTimeScheduler(false)}
                selectTime={async (date, time) => {
                  setSlot(date.set({ hour: Number(time) }));
                  setStage(2);
                }}
              />
            ) : (
              <Scheduler
                showTimeScheduler={() => setShowTimeScheduler(true)}
                isLoading={isLoading}
              />
            )
          ) : (
            <div>
              <p>
                Hey there! Your team requires an active subscription to book unlimited coaching{" "}
                <Link to={ROUTE_PATHS.COMPANY.BILLING}>Create Subscription</Link>
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const NextAvailable = () => {
  const company = useCompany();
  const companySubscription = useCompanySubscription();
  const [selectedTags, setSelectedTags] = useState<Tags>(initialTags);
  const [stage, setStage] = useState(0);
  const [slot, setSlot] = useState<DateTime | null>(null);

  const canBookSession = companySubscription.state.isActive || company.currentCompany?.isEnterprise;

  return (
    <SubscriptionPermission required>
      <div className="my-10 w-screen">
        <div className="mx-auto">
          <HtmlMeta title="Next available coach" />
          <div className="flex flex-col justify-between items-center  w-full px-2 xl:px-0 xl:w-[1000px] mx-auto">
            <div className={cn("flex self-start", { hidden: stage === 0 })}>
              <Button
                leftIcon={<ArrowLeft />}
                className="bg-bottleGreen border-bottleGreen border-solid text-white w-fit cursor-pointer z-10"
                onClick={() => setStage(stage - 1)}
              >
                Back
              </Button>
            </div>
            <div className="text-center">
              <h1 className="text-3xl font-semibold">Work with the next available coach</h1>
              <h2 className="text-lg md:text-xl !text-darkGrey  xl:max-w-[750px] xl:mx-auto font-normal pb-2">
                Filter coaches by selecting options below, or just click{" "}
                <span className="font-semibold">skip</span> and view all upcoming times coaches are
                free.
              </h2>
            </div>
            <div className="bg-white rounded-lg w-full p-4">
              <SchedulerProvider>
                {stage === 0 && (
                  <TagSelector
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    submit={() => setStage(1)}
                  />
                )}
                {stage === 1 && (
                  <Schedule
                    canBookSession={canBookSession}
                    selectedTags={selectedTags}
                    setStage={setStage}
                    setSlot={setSlot}
                  />
                )}
                {stage === 2 && <CoachOptions timeSlot={slot} selectedTags={selectedTags} />}
              </SchedulerProvider>
            </div>
          </div>
        </div>
      </div>
    </SubscriptionPermission>
  );
};
