import React, { useState } from "react";
import { Layout, PageHeader, Space } from "antd";

import { HtmlMeta } from "$/components/HtmlMeta";
import { UserEmailSettings } from "$/components/Settings/UserEmail";
import { UserNameSettings } from "$/components/Settings/UserName";
import { UserPasswordSettings } from "$/components/Settings/UserPassword";
import { UserTimezoneSettings } from "$/components/Settings/UserTimezoneSettings";

import { useScrollTo } from "$/hooks";

import { IPageBaseProps } from "$/interfaces";
import Tabs from "$/components/Tabs/Tabs";
import { useUser } from "$/state/user";
import { Input } from "$/components/Input";
import { Select } from "$/components/Select";
import Checkbox from "$/components/Checkbox";
import { Button } from "$/components/Button";
import { toast } from "$/components/Toaster";
import { UserRoleSettings } from "$/components/Settings/UserRole";

interface IProps extends IPageBaseProps {}

const limitOptions = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
];

const LimitSessions = () => {
  const user = useUser();

  const [checked, setChecked] = useState(
    user?.currentUser?.settings?.find((s) => s.key === "coachSessionLimit")?.value ?? false,
  );
  const [isLoading, setIsLoading] = useState(false);

  const defaultLimit = user?.currentUser?.settings
    ?.find((s) => s.key === "coachSessionLimit")
    ?.value?.split("_");
  const [limit, setLimit] = useState({
    value: defaultLimit?.length ? parseInt(defaultLimit[0], 10) : undefined,
    unit: defaultLimit?.length ? defaultLimit[1] : "day",
  });

  return (
    <div className="flex flex-col items-start">
      <h2>Maximum sessions for timeframe</h2>
      <Checkbox
        label={"I would like to set limit the number of sessions that can be booked with me"}
        onChange={(e) => setChecked(e.target.checked)}
        checked={checked}
        controlled
      />

      <div className="flex gap-4 items-baseline w-full mt-4">
        <p className="whitespace-nowrap"> A maximum of </p>
        <Input
          type="number"
          min="0"
          hideLabel
          className="h-[43px]"
          disabled={!checked}
          value={limit.value}
          onChange={(event) =>
            setLimit((prev) => ({ ...prev, value: parseInt(event.target.value, 10) }))
          }
        />
        <p className="whitespace-nowrap">sessions per</p>
        <Select
          options={limitOptions}
          defaultValue={
            limitOptions.find((o) => o.value === limit.unit) ?? { label: "Day", value: "day" }
          }
          hideLabel
          disabled={!checked}
          onChange={({ value }) => setLimit((prev) => ({ ...prev, unit: value }))}
        />
      </div>
      <div className="flex w-full">
        <Button
          primary
          className="w-full"
          isSubmitting={isLoading}
          onClick={async () => {
            try {
              setIsLoading(true);
              if (checked) {
                await user.update({
                  settings: {
                    upsert: [
                      {
                        create: {
                          key: "coachSessionLimit",
                          value: `${limit.value}_${limit.unit}`,
                        },
                        update: {
                          value: `${limit.value}_${limit.unit}`,
                        },
                        where: {
                          userId_key: { userId: user.currentUser.id, key: "coachSessionLimit" },
                        },
                      },
                    ],
                  },
                });
              } else {
                await user.update({
                  settings: {
                    delete: [
                      {
                        userId_key: { userId: user.currentUser.id, key: "coachSessionLimit" },
                      },
                    ],
                  },
                });
              }
              setIsLoading(false);
              toast.success(
                "Settings updated",
                "Maximum sessions for timeframe updated successfully",
              );
            } catch (error) {
              toast.error("Error", "Something went wrong. Please try again.");
              setIsLoading(false);
            }
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export const UserSettings: React.FC<IProps> = () => {
  useScrollTo();
  const [tab, setTab] = useState(0);
  const user = useUser();

  return (
    <Layout>
      <HtmlMeta title="Account Settings" />

      <Layout.Content className="site-layout-content thin">
        <PageHeader ghost className="site-page-header" title="Settings" />
        {user.currentUser.coach && (
          <Tabs tabs={[{ text: "General" }, { text: "Coach" }]} activeTab={tab} setTab={setTab} />
        )}
        {tab === 0 && (
          <div className="flex flex-col gap-4">
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <UserRoleSettings />
              <UserTimezoneSettings />
              <UserEmailSettings />
              <UserNameSettings />
              <UserPasswordSettings />
            </Space>
          </div>
        )}
        {tab === 1 && (
          <div className="flex flex-col gap-4">
            <LimitSessions />
          </div>
        )}
      </Layout.Content>
    </Layout>
  );
};
