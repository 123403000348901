import React, { useState, useEffect } from "react";
import { IPageBaseProps } from "$/interfaces";
import { HtmlMeta } from "$/components/HtmlMeta";
import { getProgrammes, useUser } from "$/state/user";
import { useIsMobile, useScrollTo } from "$/hooks";
import { OrderByArg, Session, useListSessionsQuery } from "$/graphql/types.generated";
import dayjs from "dayjs";
import { Modal } from "$/components/Modal";
import { SessionReviewModal } from "../Sessions/BookingList/components/SessionReviewModal";
import { Button } from "$/components/Button";
import { TimezoneModal } from "$/components/Modals/TimezoneModal";
import { SubscriptionDashboard } from "./SubscriptionDashboard";
import { useCompanySubscription } from "$/state/company-subscription";
import { PackageDashboard } from "./PackageDashboard";
import { useSearchParams } from "react-router-dom";
import SubscriptionEndOfProgramme from "./SubscriptionEndOfProgramme";
import { findUserType } from "$/utils/helpers/user";
import { RequestUserChange } from "$/components/RequestUserChange";

interface IProps extends IPageBaseProps {}

export const Home: React.FC<IProps> = () => {
  const user = useUser();
  const userType = findUserType(user);
  const companySubscription = useCompanySubscription();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [showTimezoneModal, setShowTimezoneModal] = useState(!user?.currentUser?.timezone);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [writeReview, setWriteReview] = useState(false);
  const { data, isLoading, isError } = useListSessionsQuery(
    {
      where: {
        teamMemberId: { equals: user.currentUser?.teamMember?.id },
        endsAt: { lte: dayjs().startOf("hour").toDate() },
        noShow: { equals: false },
        cancelledAt: { equals: null },
      },
      orderBy: [{ endsAt: OrderByArg.Desc }],
      take: 1,
    },
    { enabled: !!user.currentUser?.teamMember?.id },
  );
  const latestSession = data?.listSessions?.find((session) => !session.sessionReview?.id);

  useEffect(() => {
    if (!isError && !isLoading) {
      const noReviewSession = localStorage.getItem("noReviewSession");
      if (!latestSession?.externalId) {
        if (latestSession && noReviewSession !== latestSession.id) {
          setShowReviewModal(true);
        }
      }
    }
  }, [isLoading]);

  useEffect(() => {
    setLoading(true);
    if (searchParams.get("u") === "1") {
      user.invalidate();
      setTimeout(() => setLoading(false), 1000);
    } else {
      setLoading(false);
    }
  }, []);

  useScrollTo();
  useIsMobile();

  const packageUser =
    user.currentUser.activePackage ||
    (companySubscription.state.packageCustomer && !companySubscription.state.subscriptionCustomer);

  const completedProgramme = getProgrammes(user.currentUser.packages).find(
    (pro) => pro.hitSessionLimit && !pro.expired && pro.validSessions.length > 0,
  );

  const previousCompletedProgrammeUser =
    !user.currentUser.activePackage &&
    completedProgramme &&
    companySubscription.state.subscriptionCustomer;

  return (
    <>
      {!loading && previousCompletedProgrammeUser && <SubscriptionEndOfProgramme />}
      <div className="max-w-[1280px] mx-auto">
        <HtmlMeta title="Home" />
        <RequestUserChange />

        {!loading && <>{packageUser ? <PackageDashboard /> : <SubscriptionDashboard />}</>}

        {showTimezoneModal && <TimezoneModal onClose={() => setShowTimezoneModal(false)} />}
        {showReviewModal && (
          <Modal
            size="lg"
            onClose={() => {
              setShowReviewModal(false);
              localStorage.setItem("noReviewSession", latestSession.id);
            }}
          >
            {writeReview ? (
              <SessionReviewModal
                session={latestSession as Session}
                sessionId={latestSession.id}
                onClose={async () => {
                  setShowReviewModal(false);
                  setWriteReview(false);
                }}
              />
            ) : (
              <div className="flex flex-col items-center md:w-[80%] mx-auto gap-y-2 text-center">
                <img src="/images/Contact_us.svg" alt="" className="h-[120px] w-[120px]" />
                <h1 className="text-2xl md:text-4xl font-semibold">
                  {" "}
                  Time to review your last session with {latestSession?.coach?.displayName}
                </h1>
                <p>
                  {" "}
                  Our coaches give their time so they can gain feedback and experience. Thank you
                  for helping them grow!
                </p>

                <div className="flex flex-col-reverse gap-4 md:flex-row w-full items-center justify-center">
                  <Button
                    tertiary
                    large
                    onClick={() => {
                      setShowReviewModal(false);
                      localStorage.setItem("noReviewSession", latestSession?.id);
                    }}
                    className="w-full md:w-auto"
                  >
                    I&apos;ll review later
                  </Button>
                  <Button
                    primary
                    large
                    className="w-full md:w-auto"
                    onClick={() => setWriteReview(true)}
                  >
                    Write my review
                  </Button>
                </div>
              </div>
            )}
          </Modal>
        )}
      </div>
    </>
  );
};
