import { useRef, useEffect, useState } from "react";
import cn from "classnames";
import { Button } from "../Button";
import { Mixpanel } from "$/tracking";
import { ArrowRight } from "lucide-react";
import { useIsMobile } from "$/hooks";

const StickyBanner = () => {
  const bannerRef = useRef(null);
  const placeholderRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleScroll = () => {
      if (bannerRef.current && placeholderRef.current) {
        const bannerTop = bannerRef.current.getBoundingClientRect().top;

        if (bannerTop <= 0 && !isSticky) {
          setIsSticky(true);
        } else if (window.scrollY < placeholderRef.current.offsetTop && isSticky) {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isSticky]);

  return (
    <div>
      <div ref={placeholderRef} />
      <div
        ref={bannerRef}
        className={cn(
          "flex w-screen justify-between bg-bottleGreen rounded-b-lg px-4 py-2 text-center transition-transform duration-300",
          { "flex-col": isMobile },
          { "rounded-t-lg": isMobile && !isSticky },
          { "rounded-t-lg": !isMobile && !isSticky },
          {
            "fixed top-0 left-0 right-0 z-[2000]": isSticky,
          },
        )}
      >
        <h2
          className={cn("my-auto font-semibold pb-0 !text-white", {
            "text-xl pb-2": isMobile,
            "text-2xl": !isMobile,
          })}
        >
          {isMobile ? "Learn about More Happi for your team" : "Ready to empower your team?"}
        </h2>
        {!isMobile && (
          <p className="text-xl text-white my-auto pt-1 px-4">
            Speak to us to discuss how More Happi can support your team
          </p>
        )}
        <a href={"https://morehappi.com/book-a-call"} target="_blank" rel="noreferrer">
          <Button
            primary
            large={!isMobile}
            onClick={() => Mixpanel.track("Book a call clicked", { from: "coachesList" })}
            rightIcon={<ArrowRight size={18} />}
          >
            Book an intro call
          </Button>
        </a>
      </div>
    </div>
  );
};

export default StickyBanner;
