import { Button } from "$/components/Button";
import { Star } from "$/components/Icons";
import { Mixpanel } from "$/tracking";
import { ArrowRight } from "lucide-react";

interface ChoiceCardProps {
  title: string;
  description: string;
  imageSrc: string;
  altText: string;
  href: string;
  favourite?: boolean;
}
export const ChoiceCard = ({
  title,
  description,
  imageSrc,
  altText,
  href,
  favourite,
}: ChoiceCardProps) => {
  return (
    <div className="relative flex flex-col bg-white p-4 rounded-xl w-full md:w-1/2 lg:w-1/3">
      {favourite && (
        <div className="absolute -top-3 left-[25%] right-0 flex w-1/2 bg-[#D5881C] rounded-md justify-center z-10 gap-2">
          <Star stroke="#F5C900" fill={"#F5C900"} size={20} />
          <p className="m-0 text-white">Most Popular</p>
        </div>
      )}
      <div className="flex flex-col items-center text-center gap-x-4 pt-2">
        <h3 className="text-2xl font-semibold">{title}</h3>
        <img src={imageSrc} alt={altText} className="w-2/3" />
        <p className="m-0 pt-2">{description}</p>
      </div>
      <div className="flex justify-center pt-2">
        <a href={href}>
          <Button
            primary
            onClick={() => Mixpanel.track(`${title} clicked`, { from: "Book Coaching" })}
            rightIcon={<ArrowRight size={18} />}
          >
            {title}
          </Button>
        </a>
      </div>
    </div>
  );
};
