import { useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import { Button, Divider, Form, Image, Input, Layout, Space, Typography } from "antd";
import { useAuth } from "$/state/auth";
import { HtmlMeta } from "$/components/HtmlMeta";
import { Message } from "$/components/Message";
import { WEB_ASSETS_URL } from "$/configs/app.config";
import { DATA_TEST_IDS } from "$/elements.config";
import { Mixpanel } from "$/tracking";

/**
 * Form Validation Schema
 */
type FormInputs = {
  email: string;
  password: string;
};

const defaultValues = {
  email: "",
  password: "",
};

export const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = qs.parse(location.search);

  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [verified, setVerified] = useState<boolean>(false);

  useEffect(() => {
    if (query && query.action) {
      if (query.action === "verified") {
        setVerified(true);
      }
    }

    if (query && query.email && query.tempPassword) {
      setLoading(true);

      const loginFormQuery = async () => {
        const email = decodeURIComponent(String(query.email));
        const tempPassword = decodeURIComponent(String(query.tempPassword));

        const user = await Auth.signIn(email, tempPassword);

        Mixpanel.track("User Activated");

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          return navigate(
            `/auth/change-password?email=${encodeURIComponent(
              email,
            )}&tempPassword=${encodeURIComponent(tempPassword)}`,
          );
        }

        auth.initializeUser();
        setLoading(false);
      };

      loginFormQuery();
    }
  }, [query]);

  const onSubmit = async (values: FormInputs) => {
    try {
      setLoading(true);

      const email = values.email?.trim().toLowerCase();

      const user = await Auth.signIn(email, values.password);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        return navigate(
          `/auth/change-password?email=${encodeURIComponent(
            email,
          )}&tempPassword=${encodeURIComponent(values.password)}`,
        );
      }

      auth.initializeUser();

      setLoading(false);

      return user;
    } catch (err: any) {
      // @ts-ignore
      if (err.message.includes("User is not confirmed")) {
        return navigate(`/auth/verify?e=${encodeURIComponent(values.email)}`);
      }

      setError(err.message);
      return setLoading(false);
    }
  };

  const onSubmitFailed = (err: any) => setError(err.message);

  return (
    <Layout className="mh-layout-public">
      <HtmlMeta title="Sign In" />

      <Layout.Content>
        <div className="site-login-content">
          <div className="login-wrap">
            <a href="https://morehappi.com">
              <Image
                className="login-img"
                preview={false}
                src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
                alt="logo"
              />
            </a>

            <Space direction="vertical" size={32}>
              <Typography.Title level={1}>Sign in to your workspace</Typography.Title>

              {verified && (
                <Message
                  type="success"
                  items={[
                    {
                      message: "Your account is now verified! You can now sign in",
                    },
                  ]}
                />
              )}

              <div className="auth-form-wrap">
                <Form
                  name="login"
                  initialValues={defaultValues}
                  layout="vertical"
                  onFinish={(values) => onSubmit(values)}
                  onFinishFailed={onSubmitFailed}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    id="auth-email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please enter your email",
                      },
                    ]}
                  >
                    <Input size="large" data-cy={DATA_TEST_IDS.LOGIN.EMAIL.INPUT} />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password",
                      },
                    ]}
                  >
                    <Input.Password size="large" data-cy={DATA_TEST_IDS.LOGIN.PASSWORD.INPUT} />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      block
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={loading}
                      loading={loading}
                      data-cy={DATA_TEST_IDS.LOGIN.SUBMIT.BUTTON}
                    >
                      {loading ? "Loading... " : "Sign In"}
                    </Button>
                  </Form.Item>
                </Form>

                {error && <Message type="error" items={[{ message: error }]} />}

                <Link to="/auth/reset-password">Forgot your password?</Link>
              </div>
            </Space>

            <Divider className="divider-login" />

            <h2>Bring your Team to More Happi</h2>
            <div className="w-full flex flex-col">
              <p>Create an account and onboard your team</p>
              <a href="/auth/register">
                <Button
                  style={{
                    borderColor: "#006A39",
                    borderStyle: "solid",
                    backgroundColor: "#006A39",
                    color: "#ffffff",
                    filter: "opacity(1)",
                  }}
                  data-cy="sign-up-button"
                >
                  Get started
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};
