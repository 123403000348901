import { UserRole } from "$/graphql/types.generated";
import { IUserContext } from "$/state/user";

export const isEnterpriseUser = (user: any) => {
  return (
    user.admin?.company?.accountType === "enterprise" ||
    user.teamMember.admin.company.accountType === "enterprise"
  );
};

export const calculateAvailableSeats = (user: any) => {
  const currentTeamMembers = user.admin.teamMembers.length;
  const totalAvailableSeats = user.admin.totalSeats;
  return totalAvailableSeats - currentTeamMembers;
};

export const isActiveSub = (user: any) =>
  user.admin?.subscriptionActive || user.teamMember?.admin?.subscriptionActive;

export const isAdmin = (user: any) => user.role === "admin";

export const notEnoughSeats = (user: any) =>
  user?.admin?.availableSeats < 0 || user?.teamMember?.admin?.availableSeats < 0;

export const findUserType = (userObj: IUserContext) => {
  if (userObj.currentUser?.isCoach) return "Coach";
  if (userObj.currentUser.role === UserRole.Individual) return "Individual";
  if (userObj.currentUser?.isAdmin) return "Admin";
  if (userObj.currentUser?.isManager) return "Manager";
  else return "Team Member";
};
